<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem v-if="type === 'detail'">订单查看</BreadcrumbItem>
        <BreadcrumbItem v-else>订单审核</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <section class="card__container">
      <div class="container__title">基本信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="订单号">
              <div>
                {{ info.orderSn }}
              </div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="下单机构">
              <div>{{ info.orderCorporationName }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="买方">
              <div v-if="info.orderTypeValue === '租赁联合运营'">电行世界</div>
              <div v-else>{{ info.purchaserCorporationName }}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="卖方">
              <div>{{ info.producerCorporationName }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="订单类型">
              {{ info.orderTypeValue }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="下单时间">
              {{ tsFormat(info.createTime) }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="出厂价总额">
              {{ info.factoryTotalPrice }} 元
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item label="订单状态">
              <span
                v-if="
                  fromRouterName === 'PurchaseOrderExamineListFactory' ||
                    storeRouterName === 'PurchaseOrderExamineListFactory'
                "
              >
                {{ info.orderStatusValue }}
              </span>
              <span v-else>
                {{ info.nodeStatus }}
              </span>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="交付周期">
              {{ info.orderCycle || " - " }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8" v-if="info.orderTypeValue === '远期订单'">
            <a-form-item label="下定月份">
              <div>{{ transferOrderMonth(info.forwardOrderSkus) }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="支付方式">
              <div>{{ info.offlinePayTypeName || " - " }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="订单备注">
              <div>{{ info.orderMemo || " - " }}</div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="card__container">
      <div class="container__title">收货信息</div>
      <a-form>
        <a-row>
          <a-col :span="8">
            <a-form-item label="收货人姓名">
              <div>{{ info.consigneeRealName }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收货人电话">
              <div>{{ info.consigneeTel }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收货地址">
              <div>{{ info.consigneeAddress }}</div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="card__container">
      <div
        class="container__title between"
        v-if="info.productTypeValue === '整车'"
      >
        <div>商品信息（整车）</div>
        <a-button type="primary" @click="standardVisible = true">
          查看采购标准
        </a-button>
      </div>
      <div class="container__title" v-else>商品信息（配件）</div>
      <a-table
        v-if="
          info.orderTypeValue === '即时订单' ||
            info.orderTypeValue === '租赁联合运营'
        "
        class="commodity__table"
        :columns="commodityColumns"
        :data-source="instantOrderSkus"
        :loading="loading"
        :pagination="false"
        :rowKey="(record, index) => index"
      ></a-table>
      <div v-else>
        <a-table
          v-for="(item, index) in forwardOrderSkus"
          :key="index"
          :columns="commodityColumns"
          :data-source="item.value"
          :loading="loading"
          :pagination="false"
          :rowKey="(record, index) => index"
          class="commodity__table"
        >
          <template slot="title">
            {{ item.month }}
          </template>
        </a-table>
      </div>
    </section>
    <section
      class="card__container"
      :class="type === 'examine' ? 'bottom' : 'low__bottom'"
    >
      <div class="container__title">流程日志</div>
      <a-table
        class="log__table"
        :columns="logColumns"
        :data-source="logData"
        :loading="logLoading"
        :pagination="false"
        :rowKey="(record, index) => index"
      >
        <template slot="createTime" slot-scope="text">
          {{ tsFormat(text) }}
        </template>
        <template slot="reason" slot-scope="text">
          {{ text || " - " }}
        </template>
      </a-table>
    </section>
    <section class="examine__container" v-if="type === 'examine'">
      <a-form class="examine__form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="交付周期">
              <a-input v-model="orderCycle" @change="onChangeInput">
                <a-select
                  slot="addonAfter"
                  v-model="dateType"
                  style="width: 80px"
                >
                  <a-select-option value="day">
                    天
                  </a-select-option>
                  <a-select-option value="week">
                    周
                  </a-select-option>
                  <a-select-option value="month">
                    月
                  </a-select-option>
                </a-select>
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="16">
            <a-form-item label="审核意见">
              <a-input v-model="approvalMemo" />
            </a-form-item>
          </a-col>
        </a-row>
        <div class="operation">
          <div class="tip">
            <a-icon type="info-circle" />
            审核不通过，审核意见必填；审核通过时交付周期必填
          </div>
          <a-space>
            <a-button @click="onUnPass">不通过</a-button>
            <a-button type="primary" @click="onPass">通过</a-button>
          </a-space>
        </div>
      </a-form>
    </section>
    <div class="top__back__container" v-if="showTop" @click="onScrollTop">
      <img src="../../../assets/order/icon-top.png" alt="icon" />
      回到顶部
    </div>
    <!--  设置整车采购标准弹窗  -->
    <a-modal v-model="standardVisible" title="整车采购标准" :footer="null">
      <div class="standard__container">
        <a-input
          v-model="standInfo.purchaseMinVolume"
          class="input__standard"
          disabled
        />
        <div class="unit">m³</div>
        <div class="standard__word">≤ 商品装运总体积 ≤</div>
        <a-input
          v-model="standInfo.purchaseMaxVolume"
          class="input__standard"
          disabled
        />
        <div class="unit">m³</div>
      </div>
      <div class="standard__container top">
        <a-input
          v-model="standInfo.purchaseMinWeight"
          class="input__standard"
          disabled
        />
        <div class="unit">kg</div>
        <div class="standard__word">≤ 商品总重量 ≤</div>
        <a-input
          v-model="standInfo.purchaseMaxWeight"
          class="input__standard"
          disabled
        />
        <div class="unit">kg</div>
      </div>
    </a-modal>
  </a-layout>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import BreadcrumbItem from "@/components/BreadcrumbItem";
import {
  fetchOrderDetail,
  fetchOrderLog,
  orderExamine
} from "@/services/OrderManageService";
import { tsFormat } from "@/components/DateUtils";
import { fetchStandard } from "@/services/ProductService";

const commodityColumns = [
  {
    title: "商品编码",
    dataIndex: "skuCode",
    width: "20%"
  },
  {
    title: "商品名称",
    dataIndex: "skuName",
    width: "30%"
  },
  {
    title: "出厂单价（元）",
    dataIndex: "skuFactoryPrice",
    width: "10%"
  },
  {
    title: "采购数量",
    dataIndex: "purchaseQuantity",
    width: "10%"
  },
  {
    title: "出厂总价（元）",
    dataIndex: "skuFactoryTotalPrice",
    width: "10%"
  },
  {
    title: "装运总体积（m³）",
    dataIndex: "totalVolume",
    width: "10%"
  },
  {
    title: "装运总质量（kg）",
    dataIndex: "totalWeight",
    width: "10%"
  }
];
const logColumns = [
  {
    title: "执行人",
    dataIndex: "realName",
    width: "15%"
  },
  {
    title: "机构名称",
    dataIndex: "corporationName",
    width: "15%"
  },
  {
    title: "渠道类型",
    dataIndex: "distributorType",
    width: "15%"
  },
  {
    title: "操作时间",
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" },
    width: "20%"
  },
  {
    title: "执行动作",
    dataIndex: "operation",
    width: "5%"
  },
  {
    title: "审核意见",
    dataIndex: "reason",
    scopedSlots: { customRender: "reason" },
    width: "30%"
  }
];

export default {
  name: "PurchaseOrderDetail",
  components: { Breadcrumb, BreadcrumbItem },
  data() {
    return {
      standardVisible: false,
      purchaseMinVolume: "",
      purchaseMaxVolume: "",
      purchaseMinWeight: "",
      purchaseMaxWeight: "",
      commodityColumns,
      loading: false,
      logColumns,
      logLoading: false,
      logData: [],
      orderCycle: "",
      dateType: "day",
      orderSn: this.$route.params.orderSn,
      type: this.$route.params.type,
      info: {},
      tsFormat,
      approvalMemo: "",
      instantOrderSkus: [],
      forwardOrderSkus: [],
      showTop: false,
      standInfo: {},
      fromRouterName: "",
      storeRouterName: ""
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fromRouterName = from.name;
      if (vm.fromRouterName) {
        sessionStorage.setItem("fromRouterName", vm.fromRouterName);
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    sessionStorage.removeItem("fromRouterName");
    next();
  },
  mounted() {
    if (sessionStorage.getItem("fromRouterName")) {
      this.storeRouterName = sessionStorage.getItem("fromRouterName");
    }
    this.loadDetail();
    this.loadStandInfo();
    this.loadLogList();
    const that = this;
    window.onscroll = function() {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      const scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      if (scrollTop + windowHeight >= scrollHeight) {
        that.showTop = true;
      } else {
        that.showTop = false;
      }
    };
  },
  methods: {
    // 查询采购标准
    loadStandInfo() {
      fetchStandard(localStorage.getItem("factoryId") || "").then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.standInfo = resp.data.data || {};
        }
      });
    },
    // 加载详情
    loadDetail() {
      this.loading = true;
      const params = {
        orderSn: this.orderSn,
        type: "approval"
      };
      fetchOrderDetail(params).then(resp => {
        this.loading = false;
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          if (data.offlinePayType === 0) {
            data.offlinePayTypeName = "线下支付";
          } else if (data.offlinePayType === 1) {
            data.offlinePayTypeName = "农行支付";
          } else {
            data.offlinePayTypeName = "";
          }
          this.info = data;
          if (this.info.orderTypeValue === "远期订单") {
            this.forwardOrderSkus = this.transferTableData(
              this.info.forwardOrderSkus
            );
          } else {
            this.instantOrderSkus = this.calculate(this.info.instantOrderSkus);
          }
        }
      });
    },
    // 加载流程日志
    loadLogList() {
      this.logLoading = true;
      fetchOrderLog(this.orderSn, "producer").then(resp => {
        this.logLoading = false;
        if (resp.data.code === "SUCCESS") {
          this.logData = resp.data.data;
        }
      });
    },
    // 转化下定月份
    transferOrderMonth(obj) {
      let str = "";
      let list = [];
      for (const key in obj) {
        list.push(key);
      }
      if (list.length > 0) {
        str = list.join(",");
      } else {
        str = " - ";
      }
      return str;
    },
    // 转化远期订单表格
    transferTableData(obj) {
      let list = [];
      for (const key in obj) {
        const params = {
          value: this.calculate(obj[key]),
          month: key
        };
        list.push(params);
      }
      return list;
    },
    // 改变输入周期
    onChangeInput(e) {
      const { value } = e.target;
      const reg = /^[1-9]\d*$/;
      if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
        this.orderCycle = value;
      } else {
        this.orderCycle = "";
      }
    },
    // 不通过
    onUnPass() {
      if (!this.approvalMemo) {
        this.$message.info("请输入审核意见");
        return;
      }
      const params = {
        orderSn: this.orderSn,
        isAdopt: false,
        orderCycleNum: this.orderCycle,
        orderCycleUnit: this.dateType,
        approvalMemo: this.approvalMemo
      };
      orderExamine(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.$message.success("提交成功");
          this.$router.go(-1);
        }
      });
    },
    // 通过
    onPass() {
      if (!this.orderCycle) {
        this.$message.info("请输入交付周期");
        return;
      }
      const params = {
        orderSn: this.orderSn,
        isAdopt: true,
        orderCycleNum: this.orderCycle,
        orderCycleUnit: this.dateType,
        approvalMemo: this.approvalMemo
      };
      orderExamine(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.$message.success("提交成功");
          this.$router.go(-1);
        }
      });
    },
    // 计算
    calculate(list) {
      let arr = [...list];
      if (list && list.length > 0) {
        let totalPrice = 0;
        let num = 0;
        let volume = 0;
        let weight = 0;
        list.forEach(item => {
          num = num + parseInt(item.purchaseQuantity);
          totalPrice = totalPrice + parseFloat(item.skuFactoryTotalPrice);
          volume = volume + parseFloat(item.totalVolume);
          weight = weight + parseFloat(item.totalWeight);
        });
        const params = {
          skuCode: "合计：",
          purchaseQuantity: num,
          skuFactoryTotalPrice: totalPrice.toFixed(2),
          totalVolume: volume,
          totalWeight: weight
        };
        arr.push(params);
      }
      return arr;
    },
    // 回到顶部
    onScrollTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  }
};
</script>

<style scoped>
.container__title {
  color: rgba(16, 16, 16, 1);
  font-size: 14px;
  font-weight: bold;
}

/deep/ .ant-form-item {
  margin: 10px 40px 0 0;
}

/deep/ .ant-form-item .ant-form-item-label {
  min-width: 85px !important;
  text-align: left !important;
}

/deep/ .ant-form-item-label > label {
  color: rgba(102, 102, 102, 0.8);
  font-size: 14px;
}

.between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.standard__container {
  display: flex;
  align-items: flex-end;
}

.input__standard {
  width: 100px;
  margin-right: 10px;
}

.standard__word {
  min-width: 120px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}

.top {
  margin-top: 20px;
}

.commodity__table,
.log__table {
  margin-top: 20px;
}

.bottom {
  margin-bottom: 140px;
}

.examine__container {
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 20px 10px 20px 220px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.19);
}

.examine__form /deep/ .ant-form-item {
  display: flex;
  margin: 0 40px 0 0;
}

.operation {
  margin-top: 20px;
  margin-right: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tip {
  display: flex;
  align-items: center;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
}

.tip /deep/ .anticon {
  margin-right: 6px;
}

/deep/ .ant-form-item {
  display: flex;
}

/deep/ .ant-table-title {
  background-color: rgba(235, 240, 255, 1);
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top__back__container {
  border-radius: 4px 0px 0px 4px;
  background-color: rgba(16, 16, 16, 0.4);
  color: white;
  box-sizing: border-box;
  padding: 8px 10px 8px 15px;
  position: fixed;
  right: 0;
  bottom: 200px;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
}

.top__back__container img {
  width: 24px;
  height: 24px;
}

.low__bottom {
  margin-bottom: 30px;
}
</style>
